// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-theme-catalyst-hydrogen-src-gatsby-theme-catalyst-sanity-components-queries-page-query-js": () => import("./../node_modules/gatsby-theme-catalyst-hydrogen/src/gatsby-theme-catalyst-sanity/components/queries/page-query.js" /* webpackChunkName: "component---node-modules-gatsby-theme-catalyst-hydrogen-src-gatsby-theme-catalyst-sanity-components-queries-page-query-js" */),
  "component---node-modules-gatsby-theme-catalyst-hydrogen-src-components-home-page-home-query-js": () => import("./../node_modules/gatsby-theme-catalyst-hydrogen/src/components/home-page/home-query.js" /* webpackChunkName: "component---node-modules-gatsby-theme-catalyst-hydrogen-src-components-home-page-home-query-js" */),
  "component---node-modules-gatsby-theme-catalyst-hydrogen-src-components-work-page-work-query-js": () => import("./../node_modules/gatsby-theme-catalyst-hydrogen/src/components/work-page/work-query.js" /* webpackChunkName: "component---node-modules-gatsby-theme-catalyst-hydrogen-src-components-work-page-work-query-js" */),
  "component---content-pages-404-mdx": () => import("./../content/pages/404.mdx" /* webpackChunkName: "component---content-pages-404-mdx" */)
}

